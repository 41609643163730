@font-face {
    font-family: Sporting Groteske;
    src: url('../fonts/Dirtyline\ 36daysoftype\ 2022.otf')
}

@font-face {
    font-family: favorit;
    src: url('../fonts/FavoritBookC.ttf');
}

body{
    display:flex;
    width: 100%;
    height: 100%;
    margin:0;
    background-color: black;
    flex-direction: column-reverse;
    overflow: hidden;
}

canvas{
    position:unset;
    transition: margin-top 1.5s ease;
}




/* TITLE & MENU STYLES */

.title{
    position:absolute;
    top: 1%;
    left: 1%;
}
.title>h1{
    font-family: Sporting Groteske;
  color: #ffd6d6;
  opacity: 1;
  font-size:3em;
  margin:0;
  /* text-transform: lowercase; */
}
.title>h1>p{
    margin:0;
}

.menu>p{
    color: #ffd6d6;
    font-family: favorit;
    margin:0;
    width: 10vw;
    text-align:center;
}



/* LOADING STYLES */
#loader{
    transition: width 0s ease 4s opacity 2.5s;
    z-index: 1;
    border: 1px solid #fff;
    height: 4%;
    position: absolute;
    top: 50%;
    left: 10%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ffffff91;
}

.start{
    opacity:1;
    width: 80%;
}

.complete{
    opacity: 0;
    width: 0%;
}

#loadFilling{
    height: 100%;
    background-color: black;
}


/* WORK PORTOLIO STYLES */

#contact:hover{
    cursor:pointer;
    background-color:#ff0000b0
}

#work:hover{
    cursor:pointer;
    background-color:#ffd6d68b
}

#workbar{
    width: 100vw;
    height: 90vh;
    /* border: 1px solid blue; */
    background-color: black;
    z-index: 1;
    overflow:hidden;
}

#elements{
    height:60%;
    display: flex;
    /* position: relative; */
}

.workElement{
    flex-grow: 0;
    width: -moz-fit-content;
    width: fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    height: 100%;
    margin-top: 1vw;
    margin-left: 1vw;
    transition: flex-grow 3s;
    display: inline-block;
    position: relative;
    flex: 1;
}

.workElement:hover{
    flex-grow:25;
}

.work_hidden{
    overflow:hidden;
}

.name_cont{
    opacity: 0;
    z-index: 1;
    background-color: #ffd6d694;
    width: 3vw;
    position: absolute;
    right: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.work_name{
    color: #ffd6d6;
    margin: -1vh 0% -1vh -10px;
    font-family: sporting groteske;
    font-size: 2em;
    animation: 2s linear infinite sliding;
}

@keyframes sliding{
    0%{
        padding-left:-30%;
    }
    100%{
        padding-left:135%;
    }
}

.workElement>img{
    filter: contrast(1) grayscale(1) sepia(40%) saturate(5) hue-rotate(160deg) brightness(1.2);
    transition: opacity 0.34s ease, margin-top 0.5s ease, height 0.5s ease, filter 0.5s ease ;
    height:100%;
    width: auto;
    margin-top:0%;
    overflow: hidden;
    margin-left:50%;
    transform: translateX(-50%);
    
    /* opacity: 0.4; */
}


/* .workElement:hover>.name_cont{
    opacity: 1;
} */

.workElement:hover>img{
    height:105%;
    margin-top: -0%;
    filter: grayscale(0) sepia(0) hue-rotate(360deg);
}

.workElement:hover+div{
    opacity: 1;
}

.liminal{
    /* height: 40vh; */
    transition: opacity 3s ease;
    font-family: sporting groteske;
    color:#ffd6d6;
    font-size: 4em;
    z-index: 1;
    background-color: black;
    opacity: 0;
    position:relative;
    height: 11vh;
}

.liminal_on{
    opacity: 1
}

#banner>span{
    /* margin:1%; */
    margin-left:97vw;
    /* white-space:nowrap; */
    display:inline-block;
    min-width: 100vw;
}

#banner{
    width:80%;
    margin-left:10%;
    padding-top: 2vh;
    white-space: nowrap;
    animation: banner 50s infinite linear;
}

@keyframes banner {
    0%{
        transform:translateX(0%);
    }
    100%{
        transform:translateX(-500%);
    }
}

#work_close{
    text-align: center;
    background-color: #000;
    width: 5%;
    margin: 0 0 -7px;
    padding: 1vh;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    height: 10vh;
}


#work_close_left{
    position: absolute;
    top: 0;
    left: 0;
    padding: 1%;
    background-color: black;
    margin: 0;
    /* background-image: linear-gradient(90deg, #0000, #000000f5, black); */
    width: 1%;
    padding-top: 2vh;
}

#work_close>p:hover{
    cursor:pointer;
}

#work_close>p{
    text-align: center;
    margin: 0;
    font-family: favorit;
    font-size: 3vh;
    display: inline-block;
}

#work_close>p:hover{
    margin:0;
    margin-top: -2px;
    /* background-color: #878787; */
    border: 1px solid #ffd6d6;
}


.PF_Text{
    position:absolute;
    bottom:-10vh;
    left: 3vw;
    /* border: 1px solid blue; */
    color:#ffd6d6;
    width:80%;
    height: 40%;
    opacity: 0;
    font-family: sporting groteske;
    font-size: 4em;
    display:flex;
    flex-direction: column;
}

.info{
    display:flex;
    flex-wrap:wrap;
}

.info>p{
    font-family: favorit;
  margin: 0;
  font-size: 2.5vh;
  margin-right: 1vw;
  margin-bottom: 1vw;
  text-transform: lowercase;
}


/* contact menu! */

.contact_menu{
    transition: opacity 2s ease;
    position: absolute;
    top: 40%;
    right: 20%;
    font-family: favorit;
    opacity: 0;
}

.visible{
    opacity: 1;
}


.contact_menu>p:hover+p{
    color:#ffd6d6;
    opacity: 1;
}

.contact_menu>p, .contact_menu>a{
    /* opacity:0; */
    color:#ffd6d6;
}

.contact_menu>a{
    color:#ffd6d6;
}

#mail, #insta{
    opacity: 1;
}

#mail_hover{
    position: absolute;
    top: 40%;
    left: 20%;
}

.mail_hover{
    opacity: 0;
}

.hover_visible{
    opacity: 1;
}

.mail_hover{
    border: 1px solid #ffd6d6;
    background-color: #ffd6d67b;
    color:#ffd6d6;
    width:fit-content;
    white-space:nowrap;
    font-family: favorit;
    padding: 0.2vw 0.6vw;
}

#mail:hover{
    cursor: pointer;
    background-color:rgba(0, 0, 255, 0.499);
}

#insta:hover{
    background-color:rgba(128, 255, 0, 0.564);
}



@media only screen and (max-width: 812px) {
    /* For mobile phones: */
    #work{
        width:0;
        height:0;
        overflow:hidden;
    }
  }




